.first-section-report{
    margin: 20px 10px;

    &__left{
        .text-dates{
            font-size: 16px;
        }
    }

    &__right{
        text-align: right;;
    }
}

.footer-section-report{
    margin: 15px 0px
}