@import '../../../scss/index.scss';
.ant-affix{
    top: 0px !important;
}
.menu-top{
    display: flex;
    background-color: $background-grey-dark;
    height: 68px;
    padding: 15px 0;
    box-shadow: 0px 0px 8px rgba(0,0,0,.6);
    // position: absolute;
    // width: 100%;
    z-index: 1;

    &__left{
        flex-grow: 1;
        width: 33%;
        text-align: right;
        line-height: 2.3; 
    }

    &__right{
        flex-grow: 1;
        width: 33%;
        text-align: right;
        line-height: 1.5;
        padding-right: 10px;

        .action-btn{
            background-color: transparent;
            border-color: transparent;
            color: $font-light;
            font-size: 20px;
            font-weight: bold;

        }
    }

    &__center{
        flex-grow: 1;
        width: 33%;
        text-align: center;
        
        h1{
            line-height: 1.5; 
            color: $font-light;
            font-size: 24px;
        }
    }
}

